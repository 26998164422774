import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';
import { LoadScript } from '@react-google-maps/api';

const Routes = () => {
    return (
        
            <BrowserRouter>
                <AllRoutes />
            </BrowserRouter>
        

    );
};

export default Routes;
