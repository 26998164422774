import React, { Suspense, useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import Root from './Root';
import LoadingComponent from '../components/Loader';
import { SidebarProvider } from '../context/sidebarContext';


const Navbar = React.lazy(() => import('../components/navbar'));
const Login = React.lazy(() => import('../pages/auth/login'));
const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const Sites = React.lazy(() => import('../pages/site/site'));
const Employees = React.lazy(() => import('../pages/employee/employee'));
const OnsiteReport = React.lazy(() => import('../pages/reports/onsite'));
const MonthlyReport = React.lazy(() => import('../pages/reports/monthly'));
const UserGeoLogs = React.lazy(() => import('../pages/reports/usergeologs'));
const EmployeeReport = React.lazy(() => import('../pages/reports/employeecheckin'));
const Settings = React.lazy(() => import('../pages/settings/settings'));



type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {

    return (
        <Suspense fallback={<LoadingComponent />}>
            <Component />
        </Suspense>
    )
};


// HOC for Navbar
const NavbarComponent = ({ component: Component }: LoadComponentProps) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        // Function to update window size
        const updateSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Listen for resize events
        window.addEventListener('resize', updateSize);

        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (

        <Suspense fallback={<LoadingComponent />}>
            <SidebarProvider>
                <div className='wrapper' style={{
                    width: windowSize.width,
                    height: windowSize.height,
                }}>
                    <Navbar windowSize={windowSize}/>
                    <Component />
                </div>
            </SidebarProvider>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes(

        [
            { path: '/', element: <Root /> }, // DON'T REMOVE this is used to navigate user to respective panel after login 
            {
                // public routes
                path: '/',
                children: [
                    {
                        path: '',
                        children: [

                            { path: 'login', element: <LoadComponent component={Login} /> },
                        ],
                    },
                ],
            },
            {
                // auth protected routes
                path: '/',
                children: [
                    { path: 'dashboard', element: <NavbarComponent component={Dashboard} /> },
                    { path: 'sites', element: <NavbarComponent component={Sites} /> },
                    { path: 'employees', element: <NavbarComponent component={Employees} /> },
                    { path: 'reports/onsite', element: <NavbarComponent component={OnsiteReport} /> },
                    { path: 'reports/monthly', element: <NavbarComponent component={MonthlyReport} /> },
                    { path: 'reports/userlogs', element: <NavbarComponent component={UserGeoLogs} /> },
                    { path: 'settings', element: <NavbarComponent component={Settings} /> },
                    { path: 'reports/employee/:id', element: <NavbarComponent component={EmployeeReport} /> },
                ]
            },
        ],




    );
};
export { AllRoutes };
