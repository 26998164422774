// @flow
import React, { useState } from 'react';
import Routes from './routes/Routes';
import { Toaster } from 'react-hot-toast';

/* import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, Slide } from 'react-toastify';
import './assets/scss/Saas.scss'; */
type AppProps = {};
const App = (props: AppProps): React$Element<any> => {



    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {/* <ToastContainer transition={Slide}
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick rtl={false}
                pauseOnFocusLoss draggable pauseOnHover limit={1} /> */}
            <Routes></Routes>
        </>
    )
};

//Exporting APP
export default App;